import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../../utils/APi";
import { Link, useNavigate } from "react-router-dom";
import { removeFromCart, removeAllFromCart } from "../../store/cart/cartSlice";

import { failed, sucess } from "./../../component/toast/Toast";
import { applyCoupon, setCheckout } from "../../store/checkout/checkAction";

const Cart = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cartSlice);
  const { parch } = useSelector((state) => state.checkslice);
  const { address, token, user } = useSelector((state) => state.authSlice);
  const { coupon } = useSelector((state) => state.checkslice);
  const [couponCode, setCouponCode] = useState("");
  const [quantities, setQuantities] = useState({});
  const [selectAdres, setselectAdres] = useState("");
  const [checkoutCompleted, setCheckoutCompleted] = useState(false);

  
  const [formData, setFormData] = useState({
    address: "",
    coupon: "",
    payment_type: "",
    delivery_time: "sdfds",
    comment: "",
    points: 0,
    data: [],
  });
  const handleAddressChange = (e) => {
    const selectedAddressId = parseInt(e.target.value);

    const selectedAddress = address?.find(
      (item) => item?.id === selectedAddressId
    );
    setselectAdres(selectedAddress);
    setFormData({
      ...formData,
      address: selectedAddress?.id,
    });
  };
  const handlePaymentTypeChange = (e) => {
    const selectedPaymentType = parseInt(e.target.value); 
    setFormData({
      ...formData,
      payment_type: selectedPaymentType,
    });
  };
  const handleQuantityChange = (productId, quantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  const calculateSubtotal = (item) => {
    const quantity = quantities[item?.productId] || item?.quantity;
    return quantity * item?.price;
  };
  const calculateitems = () => {
    let total = 0;
    if (cart && cart?.length > 0) {
      total = cart?.reduce((acc, item) => {
        const subtotal = calculateSubtotal(item);

        return acc + subtotal;
      }, 0);
    }
    return total;
  };
  const calculateTotal = () => {
    let total = 0;
    if (cart && cart?.length > 0) {
      total = cart?.reduce((acc, item) => {
        const subtotal = calculateSubtotal(item);

        return acc + subtotal;
      }, 0);
    }

    if (selectAdres && selectAdres?.shipping_cost) {
      total += selectAdres?.shipping_cost;
    }
    if (coupon && coupon?.percentage) {
      total -= coupon?.percentage;
    }
    if (isNaN(total) || total < 0) {
      return 0;
    }
    return total;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      if (cart?.length > 0) {
        try {
          const result = await dispatch(
            setCheckout({ values: formData, token })
          );
          if (result.payload.status === "error") {
            failed(`${result.payload.message}`);
          } else {
            if (formData?.payment_type === 1) {
              sucess(t('Congrats ... your order has been added'));
              navigate('/profile', { state: { selectedTab: 'My orders' } });
              dispatch(removeAllFromCart());
            } else {
              setCheckoutCompleted(true);              
            }
          }
        } catch (error) {
          console.error("Error occurred while registering user:", error);
        }
      } else {
        failed(t("Cart shouldn't be Empty"));
      }
    } else {
      failed(t("first you must have an account"));
      navigate("/login");
    }
  };

  useEffect(() => {

    if (checkoutCompleted && parch?.link) {
      window.location.href = parch?.link ;
      dispatch(removeAllFromCart());
      
    }
  }, [checkoutCompleted, parch,navigate,dispatch]);


  const handleAddres = () => {
    navigate("/profile", { state: { selectedTab: "MY Address" } });
  };
  useEffect(() => {
    const formData = {
      address: selectAdres?.id,
      coupon: couponCode,
      payment_type: 2,
      delivery_time: "sdfds",
      comment: "",
      points: 0,
      data: cart?.map((item) => ({
        product_id: item?.productId,
        option1: "-1",
        option2: "-1",
        q: quantities[item?.productId] || item?.quantity,
      })),
    };
    setFormData(formData);
  }, [cart, quantities, selectAdres, couponCode]);

  return (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>{t("shopping cart")}</li>
          </ul>
        </div>
      </div>
      <div className="container-indent py-5">
        <div className="container">
          <h1 className="tt-title-subpages noborder">{t("shopping cart")}</h1>
          <div className="row">
            <div className="col-sm-12 col-xl-8">
              <div className="tt-shopcart-table">
                {cart?.length > 0 ? (
                  <table>
                    <tbody>
                      <tr className="tablebody">
                        <td>{t("product")}</td>
                        <td className="d-none d-md-table-cell">{t("name")}</td>
                        <td>{t("price")}</td>
                        <td>{t("quantity")}</td>
                        <td className="d-none d-md-table-cell">{t("total")}</td>
                      </tr>

                      {cart?.map((item, idx) => (
                        <tr className="cart-item" key={item?.productId}>
                          <td className="w-sm-full ">
                            <Link
                              to={`/products/${item?.productId}`}
                              className="tt-product-img"
                            >
                              <img
                                src={`${baseUrl}${item?.productPhoto}`}
                                alt={item?.productName}
                              />
                               <h2 className="tt-title d-block d-md-none">
                              <span>{item?.productName}</span>
                            </h2>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                            <h2 className="tt-title ">
                              <span>{item?.productName}</span> <br/>
                              <span>{item?.color}</span> {item?.color && (<span> / </span>)} 
                              <span>{item?.size}</span>
                            </h2>
                          </td>
                          <td>
                            <div className="tt-price">{item?.price}</div>
                          </td>
                          <td>
                            <div className="detach-quantity-desctope">
                              <div className="tt-input-counter style-01">
                                <i
                                  className="fa-solid fa-minus minus-btn"
                                  onClick={() =>
                                    handleQuantityChange(
                                      item?.productId,
                                      quantities[item?.productId]
                                        ? quantities[item?.productId] - 1
                                        : 1
                                    )
                                  }
                                ></i>
                               <input
  type="number"
  value={quantities[item?.productId] || item?.quantity}
  size="5"
  onChange={(e) =>
    handleQuantityChange(
      item?.productId,
      parseInt(e.target.value)
    )
  }
/>
                                <i
                                  className="fa-solid fa-plus plus-btn"
                                  onClick={() =>
                                    handleQuantityChange(
                                      item?.productId,
                                      (quantities[item?.productId] || 1) + 1
                                    )
                                  }
                                ></i>
                              </div>
                            </div>
                          </td>
                          <td className="d-none d-md-table-cell ">
                            <div className="tt-price subtotal  d-md-inline-flex">
                              <span className="mx-1"> {t("LE")}</span>
                              {calculateSubtotal(item)}
                            </div>
                          </td>
                          <td>
                            <button
                              className="tt-btn-close"
                              onClick={() =>
                                dispatch(
                                  removeFromCart({ productId: item?.productId })
                                )
                              }
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </td>
                        
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="tt-empty-cart">
                    <span className="tt-icon icon-f-39"></span>
                    <h1 className="tt-title">{t("Shopping Cart IS EMPTY")}</h1>
                    <p>{t("You have no items in your Shopping Cart.")}</p>
                    <Link to={"/"} className="btn">
                      {t("CONTINUE SHOPPING")}
                    </Link>
                  </div>
                )}
                <div className="tt-shopcart-btn">
                  <div className="col-left">
                    <Link className="btn-link" to={"/"}>
                      <i className="icon-e-19"></i>
                      {t("Continue Shopping")}
                    </Link>
                  </div>
                  <div className="col-right">
                    <button
                      className="btn-link"
                      onClick={() => dispatch(removeAllFromCart())}
                    >
                      
                      {t("clear shopping cart")}
                      <i className="fa-solid fa-trash mx-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-xl-4">
              <div className="tt-shopcart-wrapper">
                <div className="tt-shopcart-box">
                  <div className="form-group">
                    <label htmlFor="zone_id ">{t("My Address")}</label>
                    <select
                      id="zone_id"
                      name="zone_id"
                      title="Email Address"
                      className="form-control mt-3"
                      value={formData?.address?.id}
                      onChange={handleAddressChange}
                    >
                      <option value="">{t("select Address")}</option>
                      {address?.map((item, idx) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.government} - {item?.city} - {item?.zone} -{" "}
                          {item?.address}
                        </option>
                      ))}
                    </select>
                  </div>

                  <h4 className="tt-title">{t("estimate shipping and tax")}</h4>
                  <p>
                    {t("enter your destination to get a shipping estimate.")}
                  </p>
                  <Link
                    className="btn btn-lg main-btn my-3"
                    onClick={() => handleAddres()}
                  >
                    {" "}
                    {t("Add a new address")}
                  </Link>
                </div>
                <div className="tt-shopcart-box">
                  <h4 className="tt-title">{t("note")}</h4>
                  <p>{t("add special instructions for your order")}</p>
                  <div className="form-default">
                    <div className="form-group">
                      <label htmlFor="coupon">{t("coupon")} </label>
                      <input
                        type="text"
                        className="form-control"
                        id="coupon"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        placeholder={t("enter your coupon")}
                        name="coupon"
                      />
                      <button
                        className="btn btn-lg main-btn my-3"
                        onClick={() =>
                          dispatch(applyCoupon({ token, code: couponCode }))
                        }
                      >
                        {t("Apply Coupon")}
                      </button>
                    </div>
                  </div>
                </div>
                <form className="form-default">
                  <textarea className="form-control" rows="7"></textarea>
                </form>
                <div className="tt-shopcart-box tt-boredr-large">
                <table className="tt-shopcart-table01 " style={{width:"100%"}}>
  <tbody>
    <tr>
      <th className="text-center border p-1">{t("SubTotal")} :</th>
      <td className="border text-center p-1">{cart?.length > 0 && calculateitems()}</td>
    </tr>
    <tr>
      <th className="text-center border p-1">{t("Shipping Cost")} :</th>
      <td className="border text-center p-1">{selectAdres ? selectAdres?.shipping_cost : 0}</td>
    </tr>
    <tr>
      <th className="text-center border p-1">{t("coupon value")} :</th>
      <td className="border text-center p-1">{coupon&&coupon?.percentage ? coupon?.percentage : 0}</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th className="text-center border p-1">{t("Total")} :</th>
      <td className="border text-center p-1">
        <span className="mx-1"> {t("LE")}</span>
        {calculateTotal()}
      </td>
    </tr>
  </tfoot>
</table>
<div>
      <h6>{t("Select Payment Type")}:</h6>
      <div>
        <input
          type="radio"
          id="visa"
          name="paymentType"
          value={1}
          checked={formData.payment_type === 1}
          onChange={handlePaymentTypeChange}
        />
        <label htmlFor="visa">{t("Cash On Delivery")}</label>
      </div>



      
      <div>
        <input
          type="radio"
          id="paymentOnHand"
          name="paymentType"
          value={2} 
          checked={formData.payment_type === 2}
          onChange={handlePaymentTypeChange}
        />
        <label htmlFor="paymentOnHand">{t("Payment by Card")}</label>
      </div>

    </div>

                  <button
                    className="btn btn-lg main-btn my-3"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <span className="icon icon-check_circle"></span>
                    {t("proceed to checkout")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Cart;
